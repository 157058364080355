<script>
import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import SimpleLoading from "@/components/SimpleLoading.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		Footer,
		NavBarVotacion,
		SimpleLoading,
	},
	data() {
		return {
			listed: null,
			simpleLoading: false,
			itemActive: null,
			query: null,
		};
	},
	watch: {},
	created() {
		this.faqsActions("getFaqs");
		this.listed = true;
	},
	computed: {
		...mapState("faqsStore", ["faqs"]),
		computedList() {
			return this.query
				? this.faqs.filter((item) =>
						item.question
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.includes(this.query.toLowerCase())
				  )
				: this.faqs;
		},
	},
	methods: {
		...mapActions("faqsStore", ["faqsActions"]),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		setActive(index) {
			this.itemActive =
				this.itemActive != null
					? index == this.itemActive
						? null
						: index
					: index;
		},
		isActive(index) {
			return index == this.itemActive
				? "item item--active cursor-pointer gradient-red"
				: "item";
		},
	},
};
</script>

<template>
	<div class="my-2 flex flex-col overlay bg-white mx-auto">
		<div
			class="px-5 pt-8 bg-contain bg-top bg-no-repeat mt-1"
			:style="{
				'background-image': `url(${getImage('notificaciones_bg.png')})`,
			}"
		>
			<p class="b bg-overlay text-center text-xl leading-5 mb-3">
				<span class="blue block">Preguntas y Respuestas</span>
			</p>
		</div>
		<div class="mx-5 mb-3 relative flex justify-center">
			<input
				:disabled="isTour"
				id="search"
				class="search w-full flex-grow mb-2 text-lg black h-12 mt-2 pl-4 pr-8 py-2 text-lg rounded-md bg-white"
				type="text"
				placeholder="Buscar preguntas frecuentes"
				v-model="query"
			/>
			<font-awesome-icon
				v-if="!query"
				class="absolute right-3 bottom-1/2 transform translate-y-1/2 text-xl red"
				icon="fa-solid fa-magnifying-glass"
			/>
			<simple-loading
				v-if="simpleLoading && query"
				fontSize="text-2xl"
				class="absolute right-3 bottom-1/2 transform translate-y-1/2 text-xl red"
			></simple-loading>
		</div>
		<TransitionGroup
			name="list"
			tag="ul"
			class="questions-list users-list overflow-y-scroll"
		>
			<div
				@click="setActive(index)"
				v-for="({ question, answer }, index) in computedList"
				:key="index"
				class="question mb-3 rounded-xl mx-5 cursor-pointer overflow-hidden bg-white"
			>
				<div
					class="label w-full flex-grow p-2 b rounded-t-xl"
					:class="isActive(index)"
				>
					{{ question }}
				</div>
				<div v-if="index == itemActive" class="p-3">
					<span class="block text-md">{{ answer }}</span>
				</div>
			</div>
		</TransitionGroup>
		<div
			v-if="listed && computedList.length < 1"
			class="px-5 text-md m-auto font-md b blue text-center"
		>
			No se encontraron preguntas
		</div>

		<Nav-bar-votacion :active="this.$route.name"></Nav-bar-votacion>
		<Footer class="static"></Footer>
	</div>
</template>

<style lang="scss" scoped>
.list-move,
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
.list-leave-active {
	position: absolute;
}


.overlay {
	height: calc(100% - 1rem);
}

.question {
	border: get-vh(8px) solid #d6e2f0;
}

.item {
	box-shadow: 0px 8px 6px rgba($color: #224c57, $alpha: 0.25);

	&--active {
		box-shadow: none;
		color: #fff;
		border: none;

		.icon_user {
			color: $orange;
			background: $gray-light;
		}
	}
}

.search {
	border: 1px solid $green;
}
</style>
